import { LogoSpinner, Loading } from './style-spinner';

const spinner = () =>{
    return (
        <Loading>
            <LogoSpinner></LogoSpinner>
        </Loading>
    );
}

export default spinner;