import { Header, Section, TextInfo, DescriptionBold, DescriptionLight, TitleLight, TitleBold, MinBox, Box, Footer, 
    CurvedLine, Line, Circle, Oval, FacebookSVG, InstgramSVG, YoutubeSVG, LinkedinSVG, TwitterSVG, 
    LaborScoutSVG, Logo270SVG, IlustrationSVG } from './style-landing-pages';
import { useEffect, useState } from 'react';
import Spinner from '../spinner/spinner';
import GilroyExtraBold from '../../assets/font/Gilroy-ExtraBold.woff2';
import GilroyLight from '../../assets/font/Gilroy-Light.woff2'; 

const LandingPages = () => {

    const [isPending, setPending] = useState(false);

    useEffect(() => {

        //Load font in the page
        let arrayf = [
            { name: 'Gilroy ExtraBold', font: 'url(' + GilroyExtraBold + ')' },
            { name: 'Gilroy Light', font: 'url(' + GilroyLight + ')' }
        ];

        const loadFont = item => {
            return new Promise((resolved, reject) => {
                let font = new FontFace(item.name, item.font);
                font.load().then(loaled => {
                    document.fonts.add(loaled);
                    resolved();
                });
            });
        }

        Promise.all([loadFont(arrayf[0]), loadFont(arrayf[1])]).then(() => {
            setPending(true);
        });

    }, []);

    if ( !isPending){
        return (
            <Spinner></Spinner>
        );
    }

    return (
        <Box>
            <Header>
                <Oval>
                    <LaborScoutSVG></LaborScoutSVG>
                </Oval>
                <Line></Line>
                <CurvedLine></CurvedLine>
                <Circle>
                    <Logo270SVG></Logo270SVG>
                </Circle>
            </Header>
            <Section>
                <TitleLight>we are building a</TitleLight>
                <TitleBold>world of opportunitties</TitleBold>
                <TitleLight>for latin america</TitleLight>
                <DescriptionLight>A more accessible, sophisticated, and straightforward</DescriptionLight>
                <DescriptionLight>Way to search for jobs and human talent.</DescriptionLight>
                <DescriptionBold>Available March 2023.</DescriptionBold>
                <IlustrationSVG></IlustrationSVG>
            </Section>
            <Footer>
                <MinBox>
                    <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/LaborScout"><FacebookSVG></FacebookSVG></a>
                    <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/laborscout"><InstgramSVG></InstgramSVG></a>
                    <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/laborscout"><TwitterSVG></TwitterSVG></a>
                    <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/laborscout"><LinkedinSVG></LinkedinSVG></a>
                    <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UClKks35cahZzQzXsA8gvalw"><YoutubeSVG></YoutubeSVG></a>
                </MinBox>
                <MinBox>
                    <TextInfo>www.<b>laborscout</b>.co</TextInfo>
                </MinBox>
                <MinBox>
                    <TextInfo>info@<b>laborscout</b>.co</TextInfo>
                </MinBox>
            </Footer>
        </Box> 
    );
}

export default LandingPages;