import styled from 'styled-components';
import { Logo270 } from '../../assets/images/images';

const Loading = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LogoSpinner = styled(Logo270)`
    overflow: visible !important;
    width: 10rem;
    height: 10rem;
    animation: rotate 1.5s infinite linear;

    @keyframes rotate{
        0% {
            transform: rotate(0deg);
        }
    
        25%{
            transform: rotate(90deg);
        }
    
        50%{
            transform: rotate(180deg);
        }
    
        75%{
            transform: rotate(270deg);
        }
    
        100%{
            transform: rotate(360deg);
        }
    }
`;

export { Loading, LogoSpinner };

