import styled  from 'styled-components';
import { Facebook, Twitter, Instagram, Linkedin, Youtube, LaborScout, Logo270, Illustration } from '../../assets/images/images';

const Box = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    width: 100%;
    height: 13%;
    border-bottom: 0.10rem solid #FF4500;
    position: relative;

    @media all and (orientation: landscape) and (max-width: 850px){
        height: 10%;
    }
`;

const Oval = styled.div`
    width: 12rem;
    height: 4rem;
    background-color: #FFF;
    position: absolute;
    border-radius: 50%;
    right: calc(12.5% - 6rem);
    top: calc(100% - 2rem);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 780px){
        top: 0;
        left: 0;
        right: 0;
        border-radius: 0;
        width: 87.5%;
        height: 100%;
        z-index: 0;
    }

    @media all and (orientation: landscape) and (max-width: 850px){
        width: 6rem;
        height: 2rem;
        right: calc(12.5% - 3rem);
        top: calc(100% - 1rem);
        z-index: 3;
        left: unset;
    }
`;

const Line = styled.div`
    position: absolute;
    left: calc(100% - 12.5%);
    height: 100vh;
    border-left: 0.10rem solid #FF4500;
    z-index: 1;
`;

const CurvedLine = styled.div`
    position: absolute;
    height: 25vh;
    top: calc(100% - 12.5vh);
    left: calc(100% - 12.4%);
    border-left: 0.10rem dashed #FF4500;
    transform: rotate(40deg);
    z-index: 2;

    @media all and (max-width: 780px){
        display: none;
    }

    @media all and (orientation: landscape) and (max-width: 850px){
        display: block;
        height: 20vh;
        top: calc(100% - 10vh);
    }
`;

const Circle = styled.div`
    position: absolute;
    width: 5.5%;
    height: 5.5vw;
    border-radius: 50%;
    left: calc(100% - 15.25%);
    top:  calc(100vh - (20vh + 2.75vw));
    z-index: 3;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 780px){
        top: calc(100vh - (40vh + 7vw));
        left: calc(100% - 19.5%);
        width: 14vw;
        height: 14vw;
    }

    @media all and (orientation: landscape) and (max-width: 850px){
        width: 6vw;
        height: 6vw;
        top: calc(100vh - (30vh + 3vw));
        left: calc(100% - 15.5%);
    }
`;

const Section = styled.div`
    width: 100%;
    height: 67%;
    border-bottom: 0.13rem solid #002561;
    display: flex;
    flex-direction: column;
    position: relative;

    p:nth-child(1), p:nth-child(4){
        margin-top: 2.2rem;
    }

    @media all and (max-width: 780px){
        height: 47%;

        p:nth-child(4){
            margin-top: 1rem;
        }
    }

    @media all and (orientation: landscape) and (max-width: 850px){
        height: 60%;

        p:nth-child(4){
            margin-top: 0.6rem;
        }

        p:nth-child(1) {
            margin-top: 1rem;
        }
    }

    @media only screen and (min-width: 1024px)  and (max-height: 1366px) and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1.5) {
        p:nth-child(1){
            margin-top: 7rem;
        }

        p:nth-child(4), p:nth-child(5), p:nth-child(6){
            font-size: 1.6rem;
        }
    }
`;

const TitleLight = styled.p`
    margin-top: 0.01rem;
    font-family: 'Gilroy Light';
    text-transform: uppercase;
    font-size: 3.5vw;
    color: #002561;
    margin-left: 8rem;
    margin-bottom: 0.3rem;

    @media all and (max-width: 780px){
        margin-left: 1.5rem;
        font-size: 4vw;
    }

    @media all and (orientation: landscape) and (max-width: 850px){
        font-size: 2.7vw;
        margin-left: 3rem;
    }
`;

const TitleBold = styled.p`
    margin-top: 0.01rem;
    font-family: 'Gilroy ExtraBold';
    text-transform: uppercase;
    font-size: 3.5vw;
    color: #AC093A;
    margin-left: 8rem;
    margin-bottom: 0.3rem;

    @media all and (max-width: 780px){
        margin-left: 1.5rem;
        font-size: 4vw;
    }

    @media all and (orientation: landscape) and (max-width: 850px){
        font-size: 2.7vw;
        margin-left: 3rem;
    }
`;

const DescriptionLight = styled.p`
    margin-top: 0.1rem;
    font-family: 'Gilroy Light';
    color: #002561; 
    font-size: 1.5vw;
    margin-left: 8rem;
    margin-bottom: 0.2rem;

    @media all and (max-width: 780px){
        margin-left: 1.5rem;
        font-size: 2.7vw;
    }

    @media all and (orientation: landscape) and (max-width: 850px){
        font-size: 2vw;
        margin-left: 3rem;
    }
`;

const DescriptionBold = styled.p`
    margin-left: 8rem;
    margin-top: 0.1rem;
    font-family: 'Gilroy ExtraBold';
    color: #002561; 
    font-size: 1.5vw;

    @media all and (max-width: 780px){
        margin-left: 1.5rem;
        font-size: 2.7vw;
    }

    @media all and (orientation: landscape) and (max-width: 850px){
        font-size: 2vw;
        margin-left: 3rem;
    }
`;

const MinBox = styled.div`
    width: calc(84.5% / 3);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media all and (max-width: 780px){
        width: 84.5%;
        height: calc(100% / 3);
    }

    @media all and (orientation: landscape) and (max-width: 850px){
        width: calc(87.5% / 3);
        height: 100%;
    }
`;

const Footer = styled.div`
    width: 100%;
    height: 20%;
    display: flex;

    ${MinBox}:nth-child(1) a:hover svg {
        transform: translateY(-4px);
        transition:0.3s;   
    }

    @media all and (max-width: 780px){
        height: 40%;
        flex-direction: column;

        ${MinBox}:nth-child(3){
            order: 0;
            align-items: flex-end;
        }

        ${MinBox}:nth-child(1) {
            order: 1;
            align-items: flex-start;
        }

        ${MinBox}:nth-child(2) {
            order: 2;
            align-items: flex-start;
        }
    }

    @media all and (orientation: landscape) and (max-width: 850px){
        height: 30%;
        flex-direction: row;

        ${MinBox}:nth-child(1), ${MinBox}:nth-child(2), ${MinBox}:nth-child(3){
            align-items: center;
        }
    }
`;

const TextInfo = styled.p`
    font-family: 'Gilroy Light';
    font-size: 1.5rem;
    color: #002561;

    @media all and (max-width: 780px){
        font-size: 5vw;
    }

    @media all and (orientation: landscape) and (max-width: 850px){
        font-size: 2.5vw;
    }
`;

const FacebookSVG = styled(Facebook)`
    width: 1.7rem;
    height: 1.7rem;
    margin-left: 0.7rem;

    @media all and (orientation: landscape) and (max-width: 850px){
        height: 1.2rem;
        width: 1.2rem;
    }
`;

const TwitterSVG = styled(Twitter)`
    width: 1.7rem;
    height: 1.7rem;
    margin-left: 0.7rem;

    @media all and (orientation: landscape) and (max-width: 850px){
        height: 1.2rem;
        width: 1.2rem;
    }
`;

const InstgramSVG = styled(Instagram)`
    width: 1.7rem;
    height: 1.7rem;
    margin-left: 0.7rem;

    @media all and (orientation: landscape) and (max-width: 850px){
        height: 1.2rem;
        width: 1.2rem;
    }
`;

const LinkedinSVG = styled(Linkedin)`
    width: 1.7rem;
    height: 1.7rem;
    margin-left: 0.7rem;

    @media all and (orientation: landscape) and (max-width: 850px){
        height: 1.2rem;
        width: 1.2rem;
    }
`;

const YoutubeSVG = styled(Youtube)`
    width: 1.7rem;
    height: 1.7rem;
    margin-left: 0.7rem; 

    @media all and (orientation: landscape) and (max-width: 850px){
        height: 1.2rem;
        width: 1.2rem;
    }
`;

const LaborScoutSVG = styled(LaborScout)`
    width: 90%;
    height: 90%;

    @media all and (max-width: 780px){
        width: 60%;
        height: 60%;
    }

    @media all and (orientation: landscape) and (max-width: 850px){
        width: 90%;
        height: 90%;
    }
`;

const Logo270SVG = styled(Logo270)`
    width: 90%;
    height: 90%;

    @media all and (max-width: 780px){
        width: 9.8vw;
        height: 9.8vw; 
    }

    @media all and (orientation: landscape) and (max-width: 850px){
        width: 5.4vw;
        height: 5.4vw;
    }
`;

const IlustrationSVG = styled(Illustration)`
    position: absolute;
    top: calc(100% - 37vh);
    right: 13.5%;
    height: 37vh;

    @media all and (max-width: 780px){
        width: 85.7%;
        height: 40%;
        top: calc(100% - 40%);
        left: calc(87.5% - 89.7%);
    }

    @media all and (orientation: landscape) and (max-width: 850px){
        width: initial;
        height: 35vh;
        top: calc(100% - 35vh);
        left: unset;
        right: 14.5%;
    }

    @media only screen and (min-width: 1024px)  and (max-height: 1366px) and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1.5) {
        width: 80%;
        height: initial;
        top: 60%;
    }
`;

export { Header, Section, TextInfo, DescriptionBold, DescriptionLight, TitleLight, TitleBold, MinBox, Box, Footer, 
    CurvedLine, Line, Circle, Oval, FacebookSVG, TwitterSVG, InstgramSVG, LinkedinSVG, YoutubeSVG, 
    LaborScoutSVG, Logo270SVG, IlustrationSVG };
